/* eslint-disable complexity */
import { ChangeEvent } from 'react';

import { MenuItem } from '@mui/material';

import { preventInvalidChars } from '@src/lib/helper';

import PhotoInputLayout from '../PhotoInputComponent';
import { StyledButtonContainer } from '../Settings/style';

import Date from './datePicker';
import { CheckBoxInputLayout, PhoneInputLayout, RadioButtonLayout, ToggleField } from './inputFieldsLayout';
import { LinkCopied } from './linkCopy';
import { Select } from './Select';
import {
  StyledButton,
  StyledGridbox,
  StyledHeading,
  StyledHeadingContainer,
  StyledInput,
  StyledSection,
} from './styles';
import { FieldsProps, FormControlsProps, ImageErrorProps, ImageValueProps } from './types';

const FormControls = ({
  schema,
  values,
  errors,
  touched,
  handleChange: handleChangeFormik,
  setValues,
  handleBlur,
  setFieldTouched,
  isContainSpace,
  boxMode = false,
}: FormControlsProps) => {
  const { sections } = schema;

  const getInputField = (fieldProps: FieldsProps) => {
    const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      handleChangeFormik(e);

      fieldProps?.handleCustomChange?.(e);
    };

    switch (fieldProps.type) {
      case 'radio':
        return <RadioButtonLayout onChange={handleChange} onBlur={handleBlur} {...fieldProps} />;

      case 'phoneNumber':
        return <PhoneInputLayout isLogin={false} {...fieldProps} />;

      case 'toggle':
        return <ToggleField setValues={setValues} {...fieldProps} />;

      case 'checkbox':
        return (
          <CheckBoxInputLayout
            value={values[fieldProps?.id] as boolean}
            onChange={handleChange}
            onBlur={handleBlur}
            {...fieldProps}
          />
        );

      case 'button':
        return (
          <StyledButton onClick={fieldProps?.buttonClick && fieldProps?.buttonClick(values)} type='button'>
            {fieldProps.label}
          </StyledButton>
        );

      case 'photo':
        return (
          <PhotoInputLayout
            boxMode
            value={values[fieldProps?.id] as ImageValueProps}
            onChange={handleChange}
            onBlur={handleBlur}
            modelName={fieldProps?.modelName ?? 'images'}
            error={errors[fieldProps?.id] as ImageErrorProps}
            touched={touched[fieldProps?.id]}
            {...fieldProps}
          />
        );

      case 'select':
        return (
          <Select
            errors={errors}
            touched={touched}
            setFieldTouched={setFieldTouched}
            options={fieldProps.selectOptions}
            placeholder={fieldProps.label}
            {...fieldProps}
          />
        );
      case 'date':
        return <Date {...fieldProps} />;

      case 'link':
        return <LinkCopied {...fieldProps} value={values[fieldProps?.id] as string} />;
      default:
        return (
          <StyledInput
            key={fieldProps.id}
            variant='outlined'
            onChange={(e: any) => {
              const { value } = e.target;

              if (fieldProps.type === 'number') e['target']['value'] = value >= 0 ? value : 0;

              handleChange(e);
            }}
            onBlur={handleBlur}
            required={true}
            display={fieldProps.display}
            value={values[fieldProps?.id]}
            error={touched[fieldProps?.id] && !!errors[fieldProps?.id]}
            helperText={errors[fieldProps?.id] && touched[fieldProps?.id] && errors[fieldProps?.id]}
            InputLabelProps={{
              shrink: values[fieldProps?.id] === 0 || !!values[fieldProps?.id],
            }}
            onFocus={event => {
              event.target.addEventListener(
                'wheel',
                function (e) {
                  e.preventDefault();
                },
                { passive: false }
              );
            }}
            multiline={!!fieldProps?.rows}
            rows={fieldProps?.rows}
            {...fieldProps}
            {...(fieldProps.type === 'number' ? { onKeyDown: preventInvalidChars } : {})}
            disabled={fieldProps?.isDisabled}
            InputProps={{
              readOnly: fieldProps?.readOnlyText,
            }}
          >
            {fieldProps?.selectOptions?.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </StyledInput>
        );
    }
  };

  return (
    <>
      {sections.map(
        (
          {
            key,
            heading,
            gridColumn,
            inputFields,
            buttonsData,
            columnGap,
            border = true,
            isButton = false,
            isButtonClick,
          },
          index
        ) => {
          return (
            <StyledSection key={`${key}-${index}`} border={border} $isMargin={isContainSpace}>
              <StyledHeadingContainer>
                {heading && <StyledHeading>{heading}</StyledHeading>}
                {isButton && (
                  <StyledButtonContainer>
                    <StyledButton type='button' onClick={() => isButtonClick && isButtonClick(values)}>
                      Add
                    </StyledButton>
                  </StyledButtonContainer>
                )}
              </StyledHeadingContainer>
              <StyledGridbox $gridColumn={gridColumn} $columnGap={columnGap}>
                {inputFields &&
                  inputFields.map(fieldProps => {
                    return getInputField(fieldProps);
                  })}
              </StyledGridbox>
              {buttonsData &&
                buttonsData?.map(button => {
                  const { buttonContent, label: buttonLabel } = button;
                  return (
                    <StyledButton
                      key={button?.id}
                      onClick={() => buttonContent?.onClick && buttonContent?.onClick(values)}
                      type={buttonContent?.type}
                      $bgColor={buttonContent?.bgColor}
                      color={buttonContent?.color}
                      border={buttonContent?.border}
                      display={buttonContent?.display}
                      disabled={buttonContent?.disabled}
                    >
                      {buttonLabel}
                    </StyledButton>
                  );
                })}
            </StyledSection>
          );
        }
      )}
    </>
  );
};

export default FormControls;
