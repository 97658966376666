import { useCallback, useState } from 'react';
import moment from 'moment';

import { Address } from '@src/model/Address';
import { Booking } from '@src/model/Event';
import { StyledCopyContainer, StyledMessage, StyledMessageContainer } from '@src/screens/Thankyou/styles';

import Copy from '../Icons/copy';
import { StyledLink } from '../PhotoInputComponent/styles';

import {
  StyledCopyURL,
  StyledDataName,
  StyledDataSequence,
  StyledHeaderAction,
  StyledHeaderName,
  StyledHeaderSequence,
  StyledTable,
  StyledURLContainer,
} from './styles';

type LinkCopiedProps = {
  label?: string;
  url?: string;
  completeAddress?: Address;
  modelName?: string;
  bookingDetails?: Booking[];
  gridColumn?: string;
  value?: string;
};

export const LinkCopied = ({
  label,
  url = '',
  completeAddress,
  modelName,
  bookingDetails,
  gridColumn,
  value,
}: LinkCopiedProps) => {
  const [copyMessage, setCopyMessage] = useState('');

  const copyUrl = useCallback(
    async (urlValue: string) => {
      try {
        await navigator.clipboard.writeText(urlValue);
        setCopyMessage('Link copied!');
        setTimeout(() => setCopyMessage(''), 2000);
      } catch (error) {
        setCopyMessage('Failed to copy URL!');
        setTimeout(() => setCopyMessage(''), 2000);
        console.error('Failed to copy URL: ', error);
      }
    },
    [url]
  );

  const fullAddress = `${completeAddress?.address}${
    completeAddress?.apartmentDetail ? ', ' + completeAddress?.apartmentDetail : ''
  }, ${completeAddress?.emirate}`;

  const encodedAddress = encodeURIComponent(fullAddress);

  const mapUrl = `https://www.google.com/maps/search/?api=1&query=${
    (completeAddress?.latitude || completeAddress?.longitude) &&
    completeAddress?.latitude !== 'NaN' &&
    completeAddress?.longitude !== 'NaN'
      ? completeAddress?.latitude + ',' + completeAddress?.longitude
      : encodedAddress
  }
  `;

  return (
    <StyledURLContainer $gridColumn={gridColumn}>
      <StyledCopyContainer>
        <div>{label}</div>
        {copyMessage && <StyledMessage>{copyMessage}</StyledMessage>}

        {modelName === 'map' ? (
          <StyledLink href={mapUrl} target='_blank' rel='noopener noreferrer'>
            Google Maps
          </StyledLink>
        ) : modelName === 'text' ? (
          <div>{value}</div>
        ) : (
          modelName !== 'rateUsURL' && (
            <StyledMessageContainer onClick={() => copyUrl(url)}>
              <Copy />
            </StyledMessageContainer>
          )
        )}
      </StyledCopyContainer>
      {modelName === 'rateUsURL' && (
        <TableComponents data={bookingDetails} copyMessage={copyMessage} handleCopyUrl={copyUrl} />
      )}
    </StyledURLContainer>
  );
};

interface TableComponentsProps {
  data: Booking[] | undefined;
  copyMessage: string;
  handleCopyUrl?: (url: string) => void;
}

export const TableComponents = ({ data, copyMessage, handleCopyUrl }: TableComponentsProps) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          <StyledHeaderName>Order Date</StyledHeaderName>
          <StyledHeaderSequence>Order Time</StyledHeaderSequence>
          <StyledHeaderAction>URL</StyledHeaderAction>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(data) &&
          data.map((item, index) => {
            const url = `${process.env.REACT_APP_BASE_URL}/rate-us/${item._id}?button=true`;

            return (
              <tr key={`${index}-${item?._id}`}>
                <StyledDataName>{moment(item?.bookingDate).format('DD MMM, YYYY')}</StyledDataName>
                <StyledDataSequence>{moment(item?.bookingTime, 'hh:mm').format('hh:mm A')}</StyledDataSequence>
                <StyledCopyURL>
                  {handleCopyUrl && (
                    <StyledMessageContainer onClick={() => handleCopyUrl(url)}>
                      <Copy />
                    </StyledMessageContainer>
                  )}
                </StyledCopyURL>
              </tr>
            );
          })}
      </tbody>
    </StyledTable>
  );
};
