import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { isArray } from 'lodash';
import { enqueueSnackbar } from 'notistack';

import Modal from '@mui/material/Modal';

import DatePicker from '@src/components/DatePicker';
import DateSelect from '@src/components/FormComponent/datePicker';
import { dateFormatter } from '@src/lib/helper';
import { rightArrow } from '@src/lib/imgUrl';
import { CategoryData } from '@src/model/Category';
import { ResponseProps } from '@src/model/GlobalStyles';
import { DateType } from '@src/model/Package';

import {
  StyledBody,
  StyledButton,
  StyledFooter,
  StyledHeader,
  StyledHeaderText,
  StyledIcon,
  StyledLabel,
  StyledLabelInput,
  StyledLableContainer,
  StyledLeftColumn,
  StyledModalContainer,
  StyledRightColumn,
} from './styles';

export interface FilterSelectedOptions {
  label: string;
  value: string | boolean;
}

export interface FilterOptions {
  label: string;
  key: string;
  value: FilterSelectedOptions[] | JSX.Element;
  isMulti?: boolean;
  isDate?: boolean;
  isMultiDate?: boolean;
}

export interface FilterSelectOptions {
  [key: string]: string[] | boolean[] | (string | boolean)[] | string | boolean;
}

interface FilterProps {
  isOpen: boolean;
  onClose: () => void;
  handleApply: () => void;
  clearFilter: () => void;
  filterData: FilterOptions[];
  filterValue: FilterSelectOptions;
  setFilterValue: Dispatch<SetStateAction<FilterSelectOptions>>;
  setDate?: Dispatch<SetStateAction<DateType>>;
  setParentId?: Dispatch<SetStateAction<string>>;
  setSubCategoryData?: Dispatch<SetStateAction<ResponseProps<CategoryData> | undefined>>;
  date?: DateType;
  handleScrollToBottom?: () => void;
}

const FilterModal = React.memo(
  ({
    isOpen = false,
    onClose,
    filterData,
    handleApply,
    clearFilter,
    filterValue,
    setFilterValue,
    date,
    setDate,
    handleScrollToBottom,
    setParentId,
    setSubCategoryData,
  }: FilterProps) => {
    const [displayValue, setDisplayValue] = useState<number>(0);
    const [selectedFilterValues, setSelectedFilterValues] = useState<FilterSelectOptions>({});

    const selectedFilterData = useMemo(() => filterData[displayValue], [filterData, displayValue]);
    const selectedIsMulti = useMemo(() => filterData[displayValue]?.isMulti, [filterData, displayValue]);
    const selectedIsDate = useMemo(() => filterData[displayValue]?.isDate, [filterData, displayValue]);
    const selectedIsMultiDate = useMemo(() => filterData[displayValue]?.isMultiDate, [filterData, displayValue]);

    useEffect(() => {
      setSelectedFilterValues(filterValue);
    }, [isOpen, filterValue]);

    const handleLabelClick = useCallback((index: number) => {
      setDisplayValue(index);
    }, []);

    const handleClearAll = useCallback(() => {
      setSelectedFilterValues({});
      setSubCategoryData && setSubCategoryData(undefined);

      if (setDate) {
        setDate({ startDate: '', endDate: '', orderDate: null, bookingDate: null });
      }
    }, [setDate]);

    useEffect(() => {
      handleApply();
    }, [filterValue]);

    const handleApplyFilter = () => {
      if (!Object.keys(selectedFilterValues).length) {
        enqueueSnackbar('Please select at least one filter', {
          variant: 'error',
        });
      }

      setFilterValue(selectedFilterValues);
    };

    const handleFilterData = useCallback(
      (filterFields: FilterSelectedOptions) => {
        const key = selectedFilterData.key;
        setSelectedFilterValues(prev => {
          if (selectedIsMulti) {
            const prevValue = isArray(prev[key]) ? prev[key] : [];

            if (isArray(prevValue)) {
              if (typeof filterFields.value === 'string' || typeof filterFields.value === 'boolean') {
                if (prevValue.includes(filterFields.value)) {
                  return { ...prev, [key]: prevValue.filter(item => item !== filterFields.value) };
                } else {
                  return { ...prev, [key]: [...prevValue, filterFields.value] };
                }
              }
            }
          } else {
            return { ...prev, [key]: filterFields.value };
          }

          return prev;
        });
      },
      [selectedFilterData, selectedIsMulti]
    );

    const handleFilterDate = useCallback(
      (filterFields: FilterSelectedOptions) => {
        const { label, value } = filterFields;
        setSelectedFilterValues(prev => {
          const updatedFilterValues = { ...prev };

          if (value) {
            updatedFilterValues[label] = value;
          }

          return updatedFilterValues;
        });
      },
      [setSelectedFilterValues]
    );

    const onSelectValue = useCallback(
      (label: string | boolean) => {
        const value = selectedFilterValues[selectedFilterData.key];

        if (isArray(value)) {
          return (value as Array<string | boolean>).includes(label);
        } else {
          return label === value;
        }
      },
      [selectedFilterValues, selectedFilterData]
    );

    useEffect(() => {
      handleFilterDate({ label: 'startDate', value: `${dateFormatter(date?.startDate as string)}` });
      handleFilterDate({ label: 'endDate', value: `${dateFormatter(date?.endDate as string)}` });
      handleFilterDate({ label: 'orderDate', value: `${dateFormatter(date?.orderDate?.toString() as string)}` });
      handleFilterDate({ label: 'bookingDate', value: `${dateFormatter(date?.bookingDate?.toString() as string)}` });
    }, [date]);

    const handleDateChange = (newDate: Date | null) => {
      if (setDate) {
        setDate(prevDate => ({
          ...prevDate,
          orderDate: newDate,
        }));
      }
    };

    const handleBookingDateChange = (newDate: Date | null) => {
      if (setDate) {
        setDate(prevDate => ({
          ...prevDate,
          bookingDate: newDate,
        }));
      }
    };

    const handleScroll = (event: any) => {
      const { target } = event;
      const isAtBottom = target.scrollHeight - target.scrollTop - 10 <= target.clientHeight;

      if (isAtBottom) {
        handleScrollToBottom?.();
      }
    };

    useEffect(() => {
      setParentId && setParentId(selectedFilterValues?.category as string);
    }, [selectedFilterValues]);

    return (
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ zIndex: '1' }}
      >
        <StyledModalContainer>
          <StyledHeader>
            <span>Filters</span>
            <StyledHeaderText onClick={handleClearAll}>Clear all</StyledHeaderText>
          </StyledHeader>
          <StyledBody>
            <StyledLeftColumn $isDateFilter={selectedIsDate}>
              {filterData.map(({ label, key }, index) => {
                return (
                  <StyledLableContainer
                    $isSelected={selectedFilterData.key === key}
                    key={label}
                    onClick={() => handleLabelClick(index)}
                  >
                    <StyledLabel>{label}</StyledLabel>
                    <StyledIcon src={rightArrow} alt='export' />
                  </StyledLableContainer>
                );
              })}
            </StyledLeftColumn>
            <StyledRightColumn $isDateFilter={selectedIsDate} onScroll={handleScroll}>
              {isArray(selectedFilterData?.value)
                ? selectedFilterData?.value.map(({ label, value }, index) => (
                    <StyledLabelInput key={`${label}-${value}-${index}`} $isDateFilter={selectedIsDate}>
                      {!selectedIsDate && (
                        <input
                          type={selectedIsMulti ? 'checkbox' : 'radio'}
                          value={label}
                          checked={onSelectValue(value)}
                          onChange={() => handleFilterData({ label, value })}
                        />
                      )}
                      {selectedIsDate ? (
                        selectedIsMultiDate ? (
                          <DatePicker date={date as DateType} setDate={setDate} />
                        ) : (
                          <div>
                            <DateSelect
                              id={'date'}
                              label={label}
                              useFormik={false}
                              date={value === 'bookingDate' ? date?.bookingDate : date?.orderDate}
                              onDateChange={value === 'bookingDate' ? handleBookingDateChange : handleDateChange}
                            />
                          </div>
                        )
                      ) : (
                        label
                      )}
                    </StyledLabelInput>
                  ))
                : selectedFilterData?.value}
            </StyledRightColumn>
          </StyledBody>
          <StyledFooter>
            <StyledButton onClick={onClose}>Cancel</StyledButton>
            <StyledButton $primary onClick={() => handleApplyFilter()}>
              Apply
            </StyledButton>
          </StyledFooter>
        </StyledModalContainer>
      </Modal>
    );
  }
);

export default FilterModal;
