import { Rating } from '@mui/material';

import { customRatingStar, StyledRatingContainer } from './styles';

export interface RatingStarsProps {
  totalRating: number;
  count: number;
}

const RatingStars = ({ totalRating, count }: RatingStarsProps) => {
  return (
    <StyledRatingContainer>
      <Rating value={count || 0} sx={customRatingStar('medium')} precision={0.5} readOnly />({totalRating})
    </StyledRatingContainer>
  );
};

export default RatingStars;
