import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { theme } from '@src/styles/theme';

interface GenericModalProps {
  isOpen: boolean;
  modalName: string;
  onRequestClose: () => void;
  onSave: (name: string, id: string) => Promise<void>;
  defaultInputValue?: any;
  label?: string;
  type?: string;
  showErrorMessage?: boolean;
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const GenericModal: React.FC<GenericModalProps> = ({
  isOpen,
  modalName,
  onRequestClose,
  onSave,
  defaultInputValue,
  label,
  type,
  showErrorMessage,
}) => {
  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        const input = document.getElementById('modal-modal-input');
        input?.focus();
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleSave = async () => {
    await onSave(name, modalName);
    handleClose();
  };

  const handleClose = () => {
    onRequestClose();
    setName('');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let error = '';

    if (showErrorMessage) {
      if (value.startsWith(' ')) {
        error = 'Ingredient name cannot start with a space.';
      } else if (value.length < 1) {
        error = 'Ingredient name must have at least one character.';
      } else if (value.length > 100) {
        error = 'Ingredient name cannot have more than 100 characters.';
      }
    }

    setErrorMessage(error);
    setName(value);
  };

  useEffect(() => {
    defaultInputValue && setName(defaultInputValue);
  }, [defaultInputValue]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Typography id='modal-modal-title' variant='h6' component='h2'></Typography>
        {`${modalName}`}
        <TextField
          id='modal-modal-input'
          label={label || `Name`}
          variant='outlined'
          value={name}
          type={type || 'text'}
          onChange={handleInputChange}
          error={!!errorMessage}
          helperText={errorMessage}
          sx={{ mt: 2, mb: 2, width: '100%' }}
        />
        <div>
          <Button
            onClick={handleSave}
            variant='contained'
            sx={{ mr: 2, backgroundColor: theme.primaryColor.shadePurple }}
            disabled={!!errorMessage || !name}
          >
            Save
          </Button>
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default GenericModal;
