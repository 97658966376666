const Copy = () => (
  <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.0801 13.4V17.6C16.0801 21.1 14.6801 22.5 11.1801 22.5H6.98008C3.48008 22.5 2.08008 21.1 2.08008 17.6V13.4C2.08008 9.9 3.48008 8.5 6.98008 8.5H11.1801C14.6801 8.5 16.0801 9.9 16.0801 13.4Z'
      stroke='#292D32'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M22.0801 7.4V11.6C22.0801 15.1 20.6801 16.5 17.1801 16.5H16.0801V13.4C16.0801 9.9 14.6801 8.5 11.1801 8.5H8.08008V7.4C8.08008 3.9 9.48008 2.5 12.9801 2.5H17.1801C20.6801 2.5 22.0801 3.9 22.0801 7.4Z'
      stroke='#292D32'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Copy;
