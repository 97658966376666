import { AxiosError } from 'axios';
import moment from 'moment';

import {
  AddItemIcon,
  AddonsIcon,
  AllergensIcon,
  BoxIcon,
  CategoryIcon,
  ClientsIcon,
  DishIcon,
  IngredientsIcon,
  PromoIcon,
  SettingIcon,
  StaffIcon,
} from '@src/components/Icons';
import DashboardIcon from '@src/components/Icons/dashboardIcon';
import FeedBackIcon from '@src/components/Icons/feedBackIcon';
import KitchenIcon from '@src/components/Icons/kitchenReportIcon';
import LogisticsReportIcon from '@src/components/Icons/logisticsReportIcon';
import OrderReportIcon from '@src/components/Icons/orderReportIcon';
import PackageIcon from '@src/components/Icons/packages';
import ProductIcon from '@src/components/Icons/productIcon';
import PromoCodeIcon from '@src/components/Icons/promoCodeIcon';
import TransactionIcon from '@src/components/Icons/transactionReportIcon';
import { ReportItem } from '@src/components/ReportCard';
import { MenuItem } from '@src/components/Sidebar/types';
import { dropOffImg, fullBuffetImg, profile } from '@src/lib/imgUrl';
import { OrderStatus } from '@src/model/Order';
import { theme } from '@src/styles/theme';

//@TODO routes should be in different file not in constant
const UiRoutes = {
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  HOME: '/home',
  STAFF_MANAGEMENT: '/staffs',
  STAFF_DETAILS: '/staff-details/:id',
  CREATE_STAFF: '/create-staff',
  EDIT_STAFF: '/edit-staff/:id',
  CLIENT_MANAGEMENT: '/clients',
  CLIENT_DETAILS: '/client-details/:id',
  CREATE_CLIENT: '/create-client',
  EDIT_CLIENT: '/edit-client/:id',
  ALLERGEN_MANAGEMENT: '/allergens',
  CREATE_ALLERGEN: '/create-allergens',
  EDIT_ALLERGEN: '/edit-allergen/:id',
  ALLERGEN_DETAILS: '/allergen-details/:id',
  INGREDIENT_MANAGEMENT: '/ingredients',
  CATEGORY_MANAGEMENT: '/categories',
  CATEGORY_DETAILS: '/category-details/:id',
  CREATE_CATEGORY: '/create-category',
  EDIT_CATEGORY: '/edit-category/:id',
  DISH_MANAGEMENT: '/dishes',
  DISH_DETAILS: '/dish-details/:id',
  CREATE_DISH: '/create-dish',
  EDIT_DISH: '/edit-dish/:id',
  PROMO_MANAGEMENT: '/promos',
  PROMO_DETAILS: '/promo-details/:id',
  CREATE_PROMO: '/create-promo',
  EDIT_PROMO: '/edit-promo/:id',
  ADD_ON_MANAGEMENT: '/add-ons',
  ADD_ONS_DETAILS: '/addOns-details/:id',
  CREATE_ADD_ONS: '/create-addOns',
  EDIT_ADD_ONS: '/edit-addOns/:id',
  PACKAGE_MANAGEMENT: '/package',
  PACKAGE_DETAILS: '/package-details/:id',
  CREATE_PACKAGE: '/create-package',
  EDIT_PACKAGE: '/edit-package/:id',
  MENU_MANAGEMENT: '/menus',
  MENU_DETAILS: '/menu-details/:id',
  EDIT_MENU: '/edit-menu/:id',
  COPY_MENU: '/copy-menu/:id',
  CREATE_MENU: '/create-menu',
  CREATE_WEEKLY_MENU: '/create-weekly-menu',
  EDIT_WEEKLY_MENU: '/edit-weekly-menu/:id',
  WEEKLY_MENU_DETAILS: '/weekly-menu-details/:id',
  WEEKLY_MENU_MANAGEMENT: '/weekly-menu',
  COPY_WEEKLY_MENU: '/copy-weekly-menu/:id',
  ADDON_MENU_MANAGEMENT: '/addon-menus',
  ADDON_MENU_DETAILS: '/addon-menu-details/:id',
  EDIT_ADDON_MENU: '/edit-addon-menu/:id',
  COPY_ADDON_MENU: '/copy-addon-menu/:id',
  CREATE_ADDON_MENU: '/create-addon-menu',
  SETTINGS: '/settings',
  EDIT_SETTINGS: '/edit-settings/:id',
  ORDER_MANAGEMENT: '/orders',
  CREATE_ORDER: '#',
  EDIT_ORDER: '/edit-order/:id',
  CREATE_EVENT: '/event/create',
  STEPPERS: '/steppers',
  PACKAGES: '/packages',
  MENU: '/menu',
  THANKYOU: '/thankyou/:id',
  DELIVERY: '/delivery/:id',
  ORDER_DETAILS: '/create-order-details/:eventId',
  CHOOSE_MEAL: '/choose-meal',
  ORDER_SUMMARY: '/order-summary/:id',
  PAYMENT: '/payment/:id',
  PROFILE: '/profile',
  EDIT_PROFILE: '/edit-profile',
  FEEDBACK: '/feedback',
  FEEDBACK_DETAILS: '/feedback-details/:id',
  EVENT_FEEDBACK: '/feedback/event/:eventId',
  BOOKING_FEEDBACK: '/feedback/booking/:bookingId',
  DISH_FEEDBACK: '/dish-feedback',
  DISH_FEEDBACK_DETAILS: '/dish-feedback/comments/:dishId',
  PRINT_QR: '/print-qr/:id',
  PRINT_DETAILS: '/print-details/:id',
  FEEDBACK_DISH_DETAILS: '/feedback/dish-details/:id',
  FEEDBACK_ADDONS_DETAILS: '/feedback/addons-details/:id',
  DROP_OFF_CHOOSE_MEAL: '/drop-off-choose-meal',
  BOX_MANAGEMENT: '/boxes',
  CREATE_BOX: '/create-box',
};

export const clientPortalRoutes = {
  MENU_OF_THE_DAY: '/rate-us/menu-of-the-day/:id',
};

export const menuItems: MenuItem[] = [
  { label: 'Orders', route: UiRoutes.ORDER_MANAGEMENT, IconComponent: AddItemIcon },
  { label: 'Staffs', route: UiRoutes.STAFF_MANAGEMENT, IconComponent: StaffIcon },
  { label: 'Clients', route: UiRoutes.CLIENT_MANAGEMENT, IconComponent: ClientsIcon },
  { label: 'Event Feedback', route: UiRoutes.FEEDBACK, IconComponent: FeedBackIcon },
  { label: 'Dish Feedback', route: UiRoutes.DISH_FEEDBACK, IconComponent: FeedBackIcon },
  { label: 'Promos', route: UiRoutes.PROMO_MANAGEMENT, IconComponent: PromoIcon },
  { label: 'Boxes', route: UiRoutes.BOX_MANAGEMENT, IconComponent: BoxIcon },
  { label: 'Dishes', route: UiRoutes.DISH_MANAGEMENT, IconComponent: DishIcon },
  { label: 'Add-ons', route: UiRoutes.ADD_ON_MANAGEMENT, IconComponent: AddonsIcon },
  { label: 'Menu', route: UiRoutes.MENU_MANAGEMENT, IconComponent: AddItemIcon },
  { label: 'Add-on Menu', route: UiRoutes.ADDON_MENU_MANAGEMENT, IconComponent: AddItemIcon },
  { label: 'Weekly Menu', route: UiRoutes.WEEKLY_MENU_MANAGEMENT, IconComponent: AddItemIcon },
  { label: 'Packages', route: UiRoutes.PACKAGE_MANAGEMENT, IconComponent: PackageIcon },
  { label: 'Categories', route: UiRoutes.CATEGORY_MANAGEMENT, IconComponent: CategoryIcon },
  { label: 'Ingredients', route: UiRoutes.INGREDIENT_MANAGEMENT, IconComponent: IngredientsIcon },
  { label: 'Allergens', route: UiRoutes.ALLERGEN_MANAGEMENT, IconComponent: AllergensIcon },
  { label: 'Settings', route: UiRoutes.SETTINGS, IconComponent: SettingIcon },
  { label: 'Reports', route: UiRoutes.DASHBOARD, IconComponent: DashboardIcon },
];

export const KITCHEN_STAFF = 'staff';

export const SALES = 'sales';

const routeMapping = {
  [UiRoutes.STAFF_MANAGEMENT]: [
    UiRoutes.CREATE_STAFF,
    UiRoutes.EDIT_STAFF.replace('/:id', ''),
    UiRoutes.STAFF_DETAILS.replace('/:id', ''),
  ],
  [UiRoutes.CLIENT_MANAGEMENT]: [
    UiRoutes.CREATE_CLIENT,
    UiRoutes.EDIT_CLIENT.replace('/:id', ''),
    UiRoutes.CLIENT_DETAILS.replace('/:id', ''),
  ],
  [UiRoutes.CATEGORY_MANAGEMENT]: [
    UiRoutes.CREATE_CATEGORY,
    UiRoutes.EDIT_CATEGORY.replace('/:id', ''),
    UiRoutes.CATEGORY_DETAILS.replace('/:id', ''),
  ],
  [UiRoutes.ALLERGEN_MANAGEMENT]: [
    UiRoutes.CREATE_ALLERGEN,
    UiRoutes.EDIT_ALLERGEN.replace('/:id', ''),
    UiRoutes.ALLERGEN_DETAILS.replace('/:id', ''),
  ],
  [UiRoutes.DISH_MANAGEMENT]: [
    UiRoutes.CREATE_DISH,
    UiRoutes.EDIT_DISH.replace('/:id', ''),
    UiRoutes.DISH_DETAILS.replace('/:id', ''),
  ],
  [UiRoutes.PROMO_MANAGEMENT]: [
    UiRoutes.CREATE_PROMO,
    UiRoutes.EDIT_PROMO.replace('/:id', ''),
    UiRoutes.PROMO_DETAILS.replace('/:id', ''),
  ],
  [UiRoutes.ADD_ON_MANAGEMENT]: [
    UiRoutes.CREATE_ADD_ONS,
    UiRoutes.EDIT_ADD_ONS.replace('/:id', ''),
    UiRoutes.ADD_ONS_DETAILS.replace('/:id', ''),
  ],
  [UiRoutes.MENU_MANAGEMENT]: [
    UiRoutes.CREATE_MENU,
    UiRoutes.EDIT_MENU.replace('/:id', ''),
    UiRoutes.COPY_MENU.replace('/:id', ''),
    UiRoutes.MENU_DETAILS.replace('/:id', ''),
  ],
  [UiRoutes.ADDON_MENU_MANAGEMENT]: [
    UiRoutes.CREATE_ADDON_MENU,
    UiRoutes.EDIT_ADDON_MENU.replace('/:id', ''),
    UiRoutes.COPY_ADDON_MENU.replace('/:id', ''),
    UiRoutes.ADDON_MENU_DETAILS.replace('/:id', ''),
  ],
  [UiRoutes.WEEKLY_MENU_MANAGEMENT]: [
    UiRoutes.CREATE_WEEKLY_MENU,
    UiRoutes.EDIT_WEEKLY_MENU.replace('/:id', ''),
    UiRoutes.COPY_WEEKLY_MENU.replace('/:id', ''),
    UiRoutes.WEEKLY_MENU_DETAILS.replace('/:id', ''),
  ],
  [UiRoutes.SETTINGS]: [UiRoutes.EDIT_SETTINGS.replace('/:id', '')],
  [UiRoutes.PACKAGE_MANAGEMENT]: [
    UiRoutes.CREATE_PACKAGE,
    UiRoutes.EDIT_PACKAGE.replace('/:id', ''),
    UiRoutes.PACKAGE_DETAILS.replace('/:id', ''),
  ],
  [UiRoutes.ORDER_MANAGEMENT]: [
    UiRoutes.EDIT_ORDER.replace('/:id', ''),
    UiRoutes.ORDER_DETAILS.replace('/:id', ''),
    UiRoutes.ORDER_SUMMARY.replace('/:id', ''),
    UiRoutes.ORDER_DETAILS.replace('/:eventId', ''),
  ],
  [UiRoutes.FEEDBACK]: [UiRoutes.FEEDBACK_DETAILS.replace('/:id', '')],
};

export const Meals = [
  { label: 'Breakfast', key: 'breakfast' },
  { label: 'Lunch / Dinner', key: 'lunch' },
  { label: 'Break', key: 'break' },
];

interface PathTitleMap {
  [path: string]: string;
}

const pathTitleMap: PathTitleMap = {
  [UiRoutes.STAFF_MANAGEMENT]: 'Staff Management',
  [UiRoutes.CREATE_STAFF]: 'Staff Management > Create Staff',
  [UiRoutes.EDIT_STAFF.replace('/:id', '')]: 'Edit Staff',
  [UiRoutes.STAFF_DETAILS.replace('/:id', '')]: 'Staff Details',
  [UiRoutes.CLIENT_MANAGEMENT]: 'Client Management',
  [UiRoutes.CREATE_CLIENT]: 'Create Client',
  [UiRoutes.EDIT_CLIENT.replace('/:id', '')]: 'Edit Client',
  [UiRoutes.CLIENT_DETAILS.replace('/:id', '')]: 'Client Details',
  [UiRoutes.CATEGORY_MANAGEMENT]: 'Category Management',
  [UiRoutes.CREATE_CATEGORY]: 'Create Category',
  [UiRoutes.EDIT_CATEGORY.replace('/:id', '')]: 'Edit Category',
  [UiRoutes.CATEGORY_DETAILS.replace('/:id', '')]: 'Category Details',
  [UiRoutes.INGREDIENT_MANAGEMENT]: 'Ingredient Management',
  [UiRoutes.ALLERGEN_MANAGEMENT]: 'Allergen Management',
  [UiRoutes.CREATE_ALLERGEN]: 'Create Allergen',
  [UiRoutes.EDIT_ALLERGEN.replace('/:id', '')]: 'Edit Allergen',
  [UiRoutes.ALLERGEN_DETAILS.replace('/:id', '')]: 'Allergen Details',
  [UiRoutes.DISH_MANAGEMENT]: 'Dish Management',
  [UiRoutes.CREATE_DISH]: 'Create Dish',
  [UiRoutes.EDIT_DISH.replace('/:id', '')]: 'Edit Dish',
  [UiRoutes.DISH_DETAILS.replace('/:id', '')]: 'Dish Details',
  [UiRoutes.ADD_ON_MANAGEMENT]: 'AddOn Management',
  [UiRoutes.CREATE_ADD_ONS]: 'Create Add-Ons',
  [UiRoutes.EDIT_ADD_ONS.replace('/:id', '')]: 'Edit Add-Ons',
  [UiRoutes.ADD_ONS_DETAILS.replace('/:id', '')]: 'Add-Ons Details',
  [UiRoutes.PROMO_MANAGEMENT]: 'Promo Management',
  [UiRoutes.CREATE_PROMO]: 'Create Promo',
  [UiRoutes.EDIT_PROMO.replace('/:id', '')]: 'Edit Promo',
  [UiRoutes.PROMO_DETAILS.replace('/:id', '')]: 'Promo Details',
  [UiRoutes.MENU_MANAGEMENT]: 'Menu Management',
  [UiRoutes.CREATE_MENU]: 'Create Menu',
  [UiRoutes.EDIT_MENU.replace('/:id', '')]: 'Edit Menu',
  [UiRoutes.COPY_MENU.replace('/:id', '')]: 'Copy Menu',
  [UiRoutes.MENU_DETAILS.replace('/:id', '')]: 'Menu Details',
  [UiRoutes.ADDON_MENU_MANAGEMENT]: 'Add-On Menu Management',
  [UiRoutes.CREATE_ADDON_MENU]: 'Create Add-On Menu',
  [UiRoutes.EDIT_ADDON_MENU.replace('/:id', '')]: 'Edit Add-On Menu',
  [UiRoutes.COPY_ADDON_MENU.replace('/:id', '')]: 'Copy Add-On Menu',
  [UiRoutes.ADDON_MENU_DETAILS.replace('/:id', '')]: 'Add-On Menu Details',
  [UiRoutes.WEEKLY_MENU_MANAGEMENT]: 'Weekly Menu Management',
  [UiRoutes.CREATE_WEEKLY_MENU]: 'Create Weekly Menu',
  [UiRoutes.EDIT_WEEKLY_MENU.replace('/:id', '')]: 'Edit Weekly Menu',
  [UiRoutes.COPY_WEEKLY_MENU.replace('/:id', '')]: 'Copy Weekly Menu',
  [UiRoutes.WEEKLY_MENU_DETAILS.replace('/:id', '')]: 'Weekly Menu Details',
  [UiRoutes.PACKAGE_MANAGEMENT]: 'Package Management',
  [UiRoutes.CREATE_PACKAGE]: 'Create Package',
  [UiRoutes.EDIT_PACKAGE.replace('/:id', '')]: 'Edit Package',
  [UiRoutes.PACKAGE_DETAILS.replace('/:id', '')]: 'Package Details',
  [UiRoutes.ORDER_MANAGEMENT]: 'Order Management',
  [UiRoutes.ORDER_DETAILS.replace('/:id', '')]: 'Order Details',
  [UiRoutes.EDIT_ORDER.replace('/:id', '')]: 'Edit Order',
  [UiRoutes.DASHBOARD]: 'Dashboard',
};

export function getPathTitle(path: string): string {
  return pathTitleMap['/' + path.split('/')[1]] || '';
}

export function getDefaultRoute(role: string): string {
  if (role === 'superadmin') {
    return UiRoutes.STAFF_MANAGEMENT;
  } else if (role === 'admin') {
    return UiRoutes.ORDER_MANAGEMENT;
  } else if (role === 'sales') {
    //@TODO : Change Client
    return UiRoutes.ORDER_MANAGEMENT;
  } else if (role === 'staff') {
    //@TODO : Change Client
    return UiRoutes.ORDER_MANAGEMENT;
  } else {
    return '';
  }
}

export const profileData = {
  profileIcon: profile,
  userName: 'Phillip Vaccaro',
  userRole: 'Admin',
};
const validEmail = /^[\w]+(\.[\w]+)*(\+[\w]+)?@([\w-]+\.)+[a-zA-Z]{2,7}$/;
const validNameRegex = /^[a-zA-Z][a-zA-Z0-9 ]*$/;
const uaePhoneNumberRegex = /^\+?[0-9]+$/;

const statusCodes = {
  API_SUCCESS_CODE: 200,
  API_CREATE_SUCCESS_CODE: 201,
  API_ERROR_CODE: 404,
  API_ERROR_CONFIRMATION_CODE: 400,
};

export const tableHeadings = ['Date', 'Breakfast', 'Lunch', 'Break'];

export const NO_DATA_MESSAGE = 'Sorry but there is no data try to create new';
export const NO_RESULTS_MATCH = 'No matches found. Please refine your search criteria.';
export { routeMapping, statusCodes, uaePhoneNumberRegex, UiRoutes, validEmail, validNameRegex };

export const dateFormat = (date: string | undefined) => {
  const parsedDate = moment(date);

  return parsedDate.format('DD-MM-YYYY');
};

export const btnToColorMapper: Record<string, string> = {
  CONFIRMED: theme?.primaryColor?.aquamarine,
  COMPLETED: theme?.primaryColor?.denseGray,
  CANCELLED: theme?.primaryColor?.brandPaletteRed,
  REVIEWED: theme?.primaryColor?.brandPaletteOrange,
  QUOTE: theme?.primaryColor?.lunarElegance,
  DRAFT: theme?.primaryColor?.lunarElegance,
  'ACTION REQUIRED': theme.primaryColor.lightYellow,
};

export const paymentToColorMapper: Record<string, string> = {
  PENDING: theme?.primaryColor?.brandPaletteRed,
  FULLY_PAID: theme?.primaryColor?.aquamarine,
  REFUNDED: theme.primaryColor?.brandPaletteOrange,
  CREDIT_NOTED: theme?.primaryColor?.denseGray,
  PARTIAL_REFUNDED: theme?.primaryColor?.lunarElegance,
  FAILED: theme.primaryColor.lightYellow,
};

export const orderStatusList = [
  {
    name: 'Confirmed',
    id: 'CONFIRMED',
  },
  {
    name: 'Completed',
    id: 'COMPLETED',
  },
  {
    name: 'Cancelled',
    id: 'CANCELLED',
  },
  {
    name: 'Reviewed',
    id: 'REVIEWED',
  },
  {
    name: 'Quote',
    id: 'QUOTE',
  },
  {
    name: 'Draft',
    id: 'DRAFT',
  },
  {
    name: 'Action Required',
    id: 'ACTION REQUIRED',
  },
];

export const paymentStatusList = [
  {
    name: 'Pending',
    id: 'PENDING',
  },
  {
    name: 'Fully Paid',
    id: 'FULLY_PAID',
  },
  {
    name: 'Refunded',
    id: 'REFUNDED',
  },
  {
    name: 'Credit Noted',
    id: 'CREDIT_NOTED',
  },
  {
    name: 'Partially Refunded',
    id: 'PARTIALLY_REFUNDED',
  },
  {
    name: 'Failed',
    id: 'FAILED',
  },
];

export const orderStatusType = [
  { id: 1, name: 'DRAFT', label: 'Draft' },
  { id: 2, name: 'QUOTE', label: 'Quote' },
  { id: 3, name: 'REVIEWED', label: 'Reviewed' },
  { id: 4, name: 'CONFIRMED', label: 'Confirmed' },
  { id: 5, name: 'CANCELLED', label: 'Cancelled' },
  { id: 6, name: 'COMPLETED', label: 'Completed' },
  { id: 7, name: 'ACTION REQUIRED', label: 'Action Required' },
];

export const paymentStatusType = [
  { id: 1, name: 'PENDING', label: 'Pending' },
  { id: 2, name: 'FULLY_PAID', label: 'Fully Paid' },
  { id: 3, name: 'PARTIAL_REFUNDED', label: 'Partially Refunded' },
  { id: 4, name: 'REFUNDED', label: 'Refunded' },
  { id: 5, name: 'CREDIT_NOTED', label: 'Credit Noted' },
  { id: 6, name: 'FAILED', label: 'Failed' },
];

export const getPaymenStatusById = (id: number): string => {
  if (!id) {
    return '';
  }

  const matchedItem = paymentStatusType?.find((item: OrderStatus) => item?.id === id);
  return matchedItem?.name || '';
};

export const getOrderStatusNameById = (id: number): string => {
  if (!id) {
    return '';
  }

  const matchedItem = orderStatusType?.find((item: OrderStatus) => item?.id === id);
  return matchedItem?.name || '';
};

export const dateFormatter = (dateStr: string) => {
  const parsedDate = moment(dateStr);
  return parsedDate.format('YYYY-MM-DD');
};

export const serviceType = (minimumCounts: (number | null)[]) => [
  {
    id: 'buffet',
    name: 'Full Service Catering',
    content:
      'All-inclusive buffet catering, where our expert staff comes fully equipped to guarantee an impressive dining experience',
    icon: fullBuffetImg,
    label: 'Minimum person',
    minimumCount: minimumCounts[0],
  },
  {
    id: 'drop-off',
    name: 'Delivered Catering',
    content: 'Convenient boxed meal service, delivering healthy and delicious food wherever you may be.',
    icon: dropOffImg,
    label: 'Minimum person',
    minimumCount: minimumCounts[1],
  },
];

export const DEFAULT_SERVICE_TYPE = 'buffet';
export const SERVICE_TYPE_PARAM = 'id';

export const getErrorMessage = (error: unknown) => {
  const axiosError = error as AxiosError<{ message?: string }>;

  const message = axiosError?.response?.data?.message ?? 'An unexpected error occurred. Please try again later.';

  console.error('An error occurred:', axiosError);

  return message;
};

export const popularPackageName = 'premium'; //TODO: need to remove this when we get data from backend

export const emiratesData = [
  {
    label: 'Abu Dhabi',
    value: 'Abu Dhabi',
  },
  {
    label: 'Dubai',
    value: 'Dubai',
  },
  {
    label: 'Sharjah',
    value: 'Sharjah',
  },
  {
    label: 'Ajman',
    value: 'Ajman',
  },
  {
    label: 'Fujairah',
    value: 'Fujairah',
  },
  {
    label: 'Ras Al Khaimah',
    value: 'Ras Al Khaimah',
  },
  {
    label: 'Umm Al Quwain',
    value: 'Umm Al Quwain',
  },
];

export const occasionData = [
  {
    label: 'Office Party',
    value: 'Office Party',
  },
  {
    label: 'Business Event',
    value: 'Business Event',
  },
  {
    label: 'WorkShop / Seminar',
    value: 'WorkShop / Seminar',
  },
  {
    label: 'Lunch/Dinner Meeting',
    value: 'Lunch Meeting',
  },
  {
    label: 'Crew Catering',
    value: 'Crew Catering',
  },
  {
    label: 'Private Party',
    value: 'Private Party',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const description = "It's looking a bit empty here – let's fill it up with some delicious dishes!";

export const orderDetailsAlertMessage = 'Please select the package first!';

export const SAVE_LATER_MODAL_DESC =
  'Whoa there! You could lose your edits. Save your progress by giving us your email or phone number. Are you sure you want to leave ?';

export const SAVE_LATER_MODAL_HEADING = 'Heading Out?.';

export const steps = [
  { name: 'Order Details', link: UiRoutes.ORDER_DETAILS },
  { name: 'Delivery', link: UiRoutes.DELIVERY },
  { name: 'Overview', link: UiRoutes.ORDER_SUMMARY },
  { name: 'Confirmation' },
];

export const stepperStatus = ['CONFIRMED', 'COMPLETED', 'CANCELLED'];

export const WORDPRESS_BASE_URL = process.env.REACT_APP_WORDPRESS_URL;

export const orderListEditModalMessage =
  'Changing the order and package details will clear all the dish selections that you have made. Are you sure you want to continue?';

export const GOOGLE_MAP_API_STATUS_CODE = 'OK';

export const wordpressRoutes = {
  COLLABORATIONS: '/collaborations',
  ABOUT_US: '/about-us',
  BLOGS: '/blog-and-article',
  CONTACT_US: '/contact-us',
  FULL_SERVICE_CATERING: '/full-service-catering',
  DELIVERED_CATERING: '/delivered-catering',
  MENU: '/menu',
  PARTNERS: '/partners',
  EDITORIAL: '/editorial',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
};

export const ADDONS_CATEGORY = 'Addon';

export const checkAccuracyWarning =
  'This invoice is calculated based on provided prices and quantities. Ensure to review and confirm the accuracy of all charges';

export const contactSalesMessage = 'Someone from our team will connect with you in a short while.';

export const isAddonCategory = (name: string) => {
  return name?.toLowerCase()?.includes('add') && name?.toLowerCase()?.includes('on');
};

export type StatusType = 'CONFIRMED' | 'COMPLETED' | 'CANCELLED' | 'REVIEWED' | 'QUOTE' | 'DRAFT';

export const orderSummaryStatus = ['REVIEWED', 'CONFIRMED', 'QUOTE', 'COMPLETED', 'CANCELLED'];

export const checkOrderSummaryStatus = ['DRAFT', 'DEFAULT'];

export const DROP_OFF = 'drop-off';
export const BUFFET = 'buffet';

export const reportdata: ReportItem[] = [
  { id: 1, name: 'Order Report', icon: OrderReportIcon },
  { id: 2, name: 'Transaction Report', icon: TransactionIcon },
  { id: 3, name: 'Promo Code Master', icon: PromoCodeIcon },
  { id: 4, name: 'Product Master', icon: ProductIcon },
  { id: 5, name: 'Consolidated Kitchen Report', icon: KitchenIcon },
  { id: 6, name: 'Logistics Report', icon: LogisticsReportIcon },
];

export const deletePackageStatus = ['DRAFT', 'QUOTE', 'REVIEWED', 'DEFAULT'];

export const BUFFET_FULL_NAME = 'Full Service Catering';
export const DROP_OFF_FULL_NAME = 'Delivered Catering';

export const termsAndConditions = [
  'A 100% deposit is required to confirm your event booking. Alternatively, a valid Purchase Order can be submitted to secure the event date.',
  'All menu choices must be confirmed at least 2 business days prior to the event. This allows us to ensure the availability and quality of the items selected.',
  'If the event is canceled more than 2 business days before the scheduled event date for any reason, including government bans on events, the entire 100% deposit will be refundable.',
  'Cancellations made after 2:00 PM, 2 days before the event date, will result in the forfeiture of the entire 100% deposit.',
];

export const buffetWhatsIncluded = [
  'Experienced onsite service staff included',
  'Diverse menu options refreshed daily.',
  'All necessary equipment, plates, and cutlery included.',
  'Professional support team available for assistance and queries',
  'Food made from fresh high quality ingredients.',
  'Stylish buffet setups and food displays.',
  'Complete setup and cleanup services.',
  'Access to a client dashboard to view order history and user feedback',
];

export const dropOffWhatsIncluded = [
  'Food made from fresh, high-quality ingredients.',
  'Diverse menu options refreshed daily.',
  'Food packed in environmentally friendly packaging.',
  'Delivery of food to your event location.',
  'Access to a client dashboard to view order history and user feedback.',
  'Professional support team available for assistance and queries.',
];

export const EDIT_MESSAGE = 'Click here to modify your meal selections for the day.';
