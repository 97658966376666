import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import { UpdateApiTypes } from '@src/model/GlobalStyles';
import { startLoaderAction, stopLoaderAction } from '@src/redux/action/login';

import { statusCodes } from '../lib/constants';
import { getUpdateBody } from '../lib/getUpdate';
import { getCreateFields, getUpdatedFields, JsonType } from '../lib/getUpdatedFields';

interface HandleCreateEditSubmitProps {
  details: JsonType;
  defaultValues: JsonType;
  updateFunc: (data: any, id: string) => Promise<any>;
  isCopy?: boolean;
  navigateTo?: string;
  createFunc?: (data: any) => Promise<any>;
}

export const useHandleCreateEditSubmit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCreateEditSubmit = async ({
    details,
    defaultValues,
    updateFunc,
    createFunc,
    isCopy = false,
    navigateTo,
  }: HandleCreateEditSubmitProps) => {
    const loaderRequest = `unique_${Date.now()}`;

    try {
      dispatch(startLoaderAction(loaderRequest));

      let result = {} as UpdateApiTypes;

      if (id && !isCopy) {
        const { isUpdated, updatedFields } = getUpdatedFields(defaultValues, details, {
          break: '1',
          breakfast: '1',
          lunch: '1',
        });

        const updateData = getUpdateBody(updatedFields);

        if (isUpdated) {
          result = await updateFunc(updateData, id);
        } else {
          enqueueSnackbar('No changes found', {
            variant: 'warning',
          });
        }
      } else {
        const createData = getCreateFields(details);
        result = await createFunc?.(createData);
      }

      if (result?.status === statusCodes.API_CREATE_SUCCESS_CODE || result?.status === statusCodes.API_SUCCESS_CODE) {
        navigateTo ? navigate(navigateTo) : navigate(-1);
        enqueueSnackbar('Success', {
          variant: 'success',
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(stopLoaderAction(loaderRequest));
    }
  };

  return { handleCreateEditSubmit };
};
