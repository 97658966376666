import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import ObjectId from 'bson-objectid';

import ImageIcon from '@mui/icons-material/Image';
import { IconButton, Tooltip } from '@mui/material';

import { generatePresignedUrl, uploadImage } from '@src/api/presignedUrl';
import DeleteIcon from '@src/components/Icons/deleteIcon';
import { ValidateSizeTypes } from '@src/lib/helper';
import { startLoaderAction, stopLoaderAction } from '@src/redux/action/login';

import { SetImageFunction } from '../Category';
import { StyledError, StyledLabel } from '../FormComponent/styles';
import { ImageErrorProps, ImageValueProps, InputLayoutProps } from '../FormComponent/types';

import {
  StyledDetailsContainer,
  StyledLink,
  StyledMainContainer,
  StyledPhoto,
  StyledPhotoContainer,
  StyledPhotoInputContainer,
  StyledPhotoLabel,
} from './styles';

interface PhotoInputProps extends InputLayoutProps {
  value?: ImageValueProps;
  error?: ImageErrorProps;
  touched?: boolean;
  modelName: string;
  setImage?: SetImageFunction;
  boxMode?: boolean;
}

const PhotoInputLayout = ({
  id,
  label,
  value,
  gridColumn,
  gridRow,
  error,
  touched,
  modelName,
  onChange,
  onBlur,
  serviceType,
  validateSize,
  required = true,
  altName,
  setImage,
  boxMode = false,
}: PhotoInputProps) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>('');
  const [imageId, setImageId] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dimensionError, setDimensionError] = useState<string | null>(null);
  const [checkFileType, setFileType] = useState(false);
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_S3_IMAGE_BASE_URL;

  useEffect(() => {
    if (value?.url) {
      const isImageFile = /\.(jpg|jpeg|png|svg|webp|gif)$/i.test(value?.name);
      setImagePreviewUrl(isImageFile ? value.url : 'path_to_default_pdf_thumbnail');
      setImageId(value?.url.split('/').slice(-2, -1)[0]);
    } else {
      !imageId && setImageId(new ObjectId().toHexString());
    }
  }, [value, imageId]);

  const isImageDimensionValid = (width: number, height: number) => {
    let desiredWidth: number;
    let desiredHeight: number;

    switch (validateSize) {
      case ValidateSizeTypes.addons:
        desiredWidth = 620;
        desiredHeight = 314;
        break;
      case ValidateSizeTypes.dishes:
        desiredWidth = 620;
        desiredHeight = 314;
        break;
      case ValidateSizeTypes.categories:
        desiredWidth = 18;
        desiredHeight = 18;
        break;
      case ValidateSizeTypes.packages:
        desiredWidth = 61;
        desiredHeight = 46;
        break;
      case ValidateSizeTypes.allergens:
        desiredWidth = 36;
        desiredHeight = 36;
        break;
      // case ValidateSizeTypes.boxes:
      //   desiredWidth = 10000;
      //   desiredHeight = 10000;
      //   break;
      default:
        return { isValid: true, width, height };
    }

    const isValid = width >= desiredWidth && height >= desiredHeight;
    return { isValid, desiredWidth, desiredHeight };
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDimensionError(null);

    if (e.target.files) {
      const file = e.target.files[0];

      if (!file) return;

      const fileType = file.type;
      const isImageFile = fileType.startsWith('image/');
      const isPdfFile = fileType === 'application/pdf';
      const fileExtension = file.name.split('.').pop();
      const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, '').replace(/[^A-Za-z0-9]/g, '_');
      setFileType(isPdfFile);

      const sanitizedFileName = fileExtension
        ? `${fileNameWithoutExtension}.${fileExtension}`
        : fileNameWithoutExtension;

      const loaderRequest = `unique_${Date.now()}`;

      if (isImageFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const image = new Image();
          image.onload = async () => {
            const { width, height } = image;
            URL.revokeObjectURL(image.src);
            const { isValid, desiredWidth, desiredHeight } = isImageDimensionValid(width, height);

            if (isValid) {
              try {
                dispatch(startLoaderAction(loaderRequest));

                const result = await generatePresignedUrl({
                  filePath: `${modelName}/${imageId}`,
                  fileDetails: {
                    fileExtension: fileExtension ?? '',
                    fileSize: file.size,
                    fileName: sanitizedFileName,
                  },
                });

                if (result?.url) await uploadImage(result.url, file);

                const syntheticEvent: React.ChangeEvent<HTMLInputElement> = {
                  target: {
                    name: id,
                    value: !boxMode
                      ? {
                          name: sanitizedFileName,
                          url: `${BASE_URL}${modelName}/${imageId}/${sanitizedFileName}`,
                          type: file.type,
                          serviceType: serviceType,
                        }
                      : {
                          name: sanitizedFileName,
                          url: `${BASE_URL}${modelName}/${imageId}/${sanitizedFileName}`,
                          type: file.type,
                        },
                    files: e.target.files,
                  } as unknown as HTMLInputElement,
                } as React.ChangeEvent<HTMLInputElement>;

                onChange && onChange(syntheticEvent);
              } catch (uploadFileError) {
                console.error(uploadFileError);
              } finally {
                dispatch(stopLoaderAction(loaderRequest));
              }
            } else {
              setDimensionError(
                `Image dimensions must be at least ${desiredWidth}x${desiredHeight} pixels. ` +
                  `Current dimensions are ${width}x${height}.`
              );
            }
          };
          image.src = URL.createObjectURL(file);
        };

        reader.readAsDataURL(file);
      } else if (isPdfFile) {
        try {
          dispatch(startLoaderAction(loaderRequest));

          const result = await generatePresignedUrl({
            filePath: `${modelName}/${imageId}`,
            fileDetails: {
              fileExtension: fileExtension ?? '',
              fileSize: file.size,
              fileName: sanitizedFileName,
            },
          });

          if (result?.url) await uploadImage(result.url, file);

          const syntheticEvent: React.ChangeEvent<HTMLInputElement> = {
            target: {
              name: id,
              value: {
                name: sanitizedFileName,
                url: `${BASE_URL}${modelName}/${imageId}/${sanitizedFileName}`,
                type: file.type,
                serviceType: serviceType,
              },
              files: e.target.files,
            } as unknown as HTMLInputElement,
          } as React.ChangeEvent<HTMLInputElement>;

          onChange && onChange(syntheticEvent);
        } catch (uploadFileError) {
          console.log(uploadFileError);
        } finally {
          dispatch(stopLoaderAction(loaderRequest));
        }
      } else {
        console.error('Unsupported file type');
      }
    }
  };

  const handleDeleteImage = () => {
    setImagePreviewUrl('');

    if (onChange) {
      const syntheticEvent: React.ChangeEvent<HTMLInputElement> = {
        target: {
          name: id,
          value: {},
          files: null,
        } as HTMLInputElement,
      } as React.ChangeEvent<HTMLInputElement>;

      onChange(syntheticEvent);
    }

    if (setImage) {
      setImage(undefined);
    }
  };

  const handleImageClick = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <StyledMainContainer $gridColumn={gridColumn} $gridRow={gridRow}>
      <StyledLabel id={id}>{label + (required ? ' *' : '')}</StyledLabel>
      <StyledPhotoInputContainer $isPdfFile={checkFileType}>
        <StyledPhotoContainer onClick={handleImageClick}>
          {imagePreviewUrl ? (
            /\.(jpg|jpeg|png|gif|webp|svg)$/i.test(value?.name || '') ? (
              <StyledPhoto src={imagePreviewUrl} alt={altName} />
            ) : (
              <div>
                {value?.url && (
                  <StyledLink href={value.url} target='_blank'>
                    View PDF
                  </StyledLink>
                )}
              </div>
            )
          ) : (
            <>
              <input
                type='file'
                accept='image/*, application/pdf'
                onChange={handleImageChange}
                onBlur={onBlur}
                style={{ display: 'none' }}
                id={id}
                name={id}
                ref={fileInputRef}
              />
              <ImageIcon />
            </>
          )}
        </StyledPhotoContainer>
        <StyledDetailsContainer>
          <Tooltip title={value?.name} placement='bottom'>
            <StyledPhotoLabel>{value?.name ? value.name : 'No file chosen'}</StyledPhotoLabel>
          </Tooltip>
          {imagePreviewUrl && (
            <IconButton onClick={handleDeleteImage}>
              <DeleteIcon />
            </IconButton>
          )}
        </StyledDetailsContainer>
      </StyledPhotoInputContainer>
      {dimensionError && <StyledError>{dimensionError}</StyledError>}
      {error && touched && <StyledError>{error?.name ?? error?.type ?? error?.url}</StyledError>}
    </StyledMainContainer>
  );
};

export default PhotoInputLayout;
