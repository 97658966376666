import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce, size } from 'lodash';

import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { FilterIcon, SearchIcon } from '@src/components/Icons';
import FilterRemoveIcon from '@src/components/Icons/filterRemoveIcon';
import { exportIcon } from '@src/lib/imgUrl';
import { CategoryData } from '@src/model/Category';
import { ResponseProps } from '@src/model/GlobalStyles';
import { DateType } from '@src/model/Package';

import FilterModal, { FilterOptions, FilterSelectOptions } from '../Modals/FilterModal';
import RatingStars, { RatingStarsProps } from '../RatingStars';

import {
  StyledClearButton,
  StyledHelmetContainer,
  StyledIcon,
  StyledItemContainer,
  StyledLeftContainer,
  StyledMenuItem,
  StyledRightContainer,
  StyledSearchBar,
  StyledSearchInput,
  StyledText,
} from './style';

export interface HelmetProps {
  search?: {
    onSearch: (textToSearch: string) => void;
    onClearSearch: () => void;
    placeholder?: string;
  };
  copy?: {
    onCopy: () => void;
    icon?: React.ReactNode;
    text: string;
  };
  filters?: {
    filterOptions: FilterOptions[];
    setFilterValue: Dispatch<SetStateAction<FilterSelectOptions>>;
    handleFilter: () => void;
    clearFilter: () => void;
    defaultFilter: FilterSelectOptions;
    handleScrollToBottom?: () => void;
  };
  exportCSV?: {
    text: string;
    handleExport: () => void;
  };
  ctaButton?: {
    text: string;
    icon?: React.FC;
    handleCTA: () => void;
  };
  orderButton?: {
    text: string;
    icon?: React.FC;
    handleCreate: () => void;
  };
  subHeader?: string;
  showBackButton?: boolean;
  date?: DateType;
  setDate?: Dispatch<SetStateAction<DateType>>;
  rating?: RatingStarsProps;
  setParentId?: Dispatch<SetStateAction<string>>;
  setSubCategoryData?: Dispatch<SetStateAction<ResponseProps<CategoryData> | undefined>>;
}

const Helmet = ({
  search,
  filters,
  exportCSV,
  ctaButton,
  orderButton,
  subHeader,
  copy,
  showBackButton = false,
  date,
  rating,
  setDate,
  setParentId,
  setSubCategoryData,
}: HelmetProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [showSearchBar, setSearchBar] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showRemovefilter, setRemovefilter] = useState<boolean>(false);
  const IconComponent = ctaButton?.icon;

  const debounceSearch = useCallback(
    debounce((text: string) => {
      search?.onSearch(text.trim());
    }, 600),
    []
  );

  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      search?.onClearSearch();
      setSearchBar(false);
    }
  };

  useEffect(() => {
    if (showSearchBar && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [showSearchBar]);

  return (
    <StyledHelmetContainer>
      <StyledRightContainer>
        {showBackButton && (
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
        )}
        <StyledMenuItem>{subHeader}</StyledMenuItem>
        {!!rating && <RatingStars {...rating} />}
      </StyledRightContainer>
      <StyledLeftContainer>
        {size(filters?.defaultFilter) && showRemovefilter ? (
          <StyledItemContainer
            onClick={() => {
              filters?.clearFilter(), setRemovefilter(false);
            }}
          >
            <FilterRemoveIcon />
          </StyledItemContainer>
        ) : null}
        {showSearchBar ? (
          <StyledSearchBar>
            <StyledSearchInput
              type='text'
              placeholder={search?.placeholder ? search?.placeholder : 'Search...'}
              onChange={(event: { target: { value: string } }) => debounceSearch(event.target.value)}
              ref={inputRef}
            />
            <StyledClearButton onClick={clearInput}>X</StyledClearButton>
          </StyledSearchBar>
        ) : null}

        {search && !showSearchBar ? (
          <StyledItemContainer
            onClick={() => {
              setSearchBar(true);
            }}
          >
            <SearchIcon />
          </StyledItemContainer>
        ) : null}

        {filters && (
          <>
            <StyledItemContainer onClick={() => setIsOpen(true)}>
              <FilterIcon />
            </StyledItemContainer>
            <FilterModal
              setSubCategoryData={setSubCategoryData}
              setParentId={setParentId}
              setDate={setDate}
              date={date}
              isOpen={isOpen}
              onClose={() => {
                setIsOpen(false);
              }}
              filterData={filters?.filterOptions}
              handleApply={() => {
                filters?.handleFilter(), setIsOpen(false);
                setRemovefilter(true);
              }}
              clearFilter={() => {
                filters.clearFilter();
                setRemovefilter(false);
              }}
              filterValue={filters?.defaultFilter}
              setFilterValue={filters?.setFilterValue}
              handleScrollToBottom={filters?.handleScrollToBottom}
            />
          </>
        )}

        {copy ? (
          <StyledItemContainer
            onClick={() => {
              copy?.onCopy();
            }}
          >
            {copy?.icon}
            <StyledText>{copy.text}</StyledText>
          </StyledItemContainer>
        ) : null}
        {orderButton && (
          <StyledItemContainer
            onClick={() => {
              orderButton?.handleCreate();
            }}
          >
            {IconComponent ? <IconComponent /> : null}
            <StyledText>{orderButton.text}</StyledText>
          </StyledItemContainer>
        )}
        {ctaButton && (
          <StyledItemContainer
            onClick={() => {
              ctaButton?.handleCTA();
            }}
          >
            {IconComponent ? <IconComponent /> : null}
            <StyledText>{ctaButton.text}</StyledText>
          </StyledItemContainer>
        )}
        {exportCSV && (
          <StyledItemContainer
            onClick={() => {
              exportCSV.handleExport();
            }}
          >
            <StyledIcon src={exportIcon} alt='export' />
            <StyledText>{exportCSV.text}</StyledText>
          </StyledItemContainer>
        )}
      </StyledLeftContainer>
    </StyledHelmetContainer>
  );
};

export default Helmet;
