import styled from 'styled-components';

import { bgImgThankYouCard } from '@src/lib/imgUrl';
import { Breakpoints } from '@src/styles/breakpoints';

export const StyledContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  color: ${({ theme }) => theme.primaryColor.deepSeaBlue};
  width: 100%;
  height: 88vh;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  padding: 5% 4%;
  gap: 16px;
  border: 1px solid ${({ theme }) => theme.primaryColor.lunarElegance};
  border-radius: 16px;
  margin: 16px 0;
  background: ${({ theme }) => theme.primaryColor.white};
  ${Breakpoints.TABLET} {
    flex-direction: column;
    align-items: center;
    padding: 40px 10px;
  }
`;

export const StyledOrderImage = styled.img`
  width: 20%;
  height: 100%;
  ${Breakpoints.TABLET} {
    width: 35%;
  }
  ${Breakpoints.MOBILE} {
    width: 50%;
  }
`;

export const StyledHeading = styled.div`
  font-family: 'the-seasons', Lato;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.get('2xl')};
  padding: 5px 0px;
  line-height: 130%;
`;
export const StyledThankSpan = styled.span`
  background: url(${bgImgThankYouCard}) no-repeat bottom/cover;
  background-size: 100% auto;
`;

export const StyledDetailsContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: ${({ theme }) => theme.fontSize.get('s')};
  ${Breakpoints.TABLET} {
    width: 100%;
  }
`;
export const StyledDetailsSubContainer = styled.div<{
  $isBgCream?: boolean;
  $isBgSnowWhite?: boolean;
  $isFooter?: boolean;
}>`
  color: ${({ theme }) => theme.primaryColor.fluentBlack};
  border-radius: 8px;
  padding: 8px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${({ theme, $isBgSnowWhite }) =>
    $isBgSnowWhite
      ? `background-color: ${theme.primaryColor.snow};
    gap:8px;
    `
      : ``}
  ${({ theme, $isBgCream }) =>
    $isBgCream
      ? `background-color: ${theme.primaryColor.cream};
    gap:16px;
    `
      : ``}

${({ theme, $isFooter }) =>
    $isFooter
      ? `font-size:${theme.fontSize.get('xs')};
      ${Breakpoints.MOBILE}{
        text-align:center;
      }
    `
      : ``}
`;

export const StyledOrderedList = styled.ol`
  padding-inline-start: 15px;
  margin-block-start: 8px;
  margin-block-end: 0;
  line-height: 25.2px;
`;
export const StyledContactComponent = styled.div`
  display: flex;
  gap: 5%;
  flex-wrap: wrap;
  ${Breakpoints.MOBILE} {
    gap: 10px;
  }
`;
export const StyledContactInfoComponent = styled.div`
  display: flex;
  gap: 8px;
  text-wrap: nowrap;
  align-items: center;
`;
export const StyledWidthComponent = styled.div`
  border-top: 1px solid ${({ theme }) => theme.primaryColor.lunarElegance};
  padding-top: 8px;
`;

export const StyledMessage = styled.div`
  color: ${({ theme }) => theme.primaryColor.royalBlue};
`;

export const StyledMessageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const StyledCopyContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
