import apiEndpoints from '@src/lib/apiEndpoints';
import { Box } from '@src/model/Box';

import apiRequest from './api';

export const createBox = (data: Box) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.CREATE_BOX}`,
    data,
  });
};

export const getBoxes = (data: any) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_BOXES}`,
    data,
  });
};
