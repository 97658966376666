import { useEffect, useRef, useState } from 'react';
import { Calendar, DateRange } from 'react-date-range';
import { enUS } from 'date-fns/locale';

import { CalendarIcon } from '@radix-ui/react-icons';

import { formatDates } from '@src/lib/helper';
import { theme } from '@src/styles/theme';

import { StyledCheckBoxContainer } from '../FormComponent/styles';
import { Date as DateType } from '../Modals/EventDetailModal';

import {
  StyledCheckboxInput,
  StyledCheckboxLabel,
  StyledContainer,
  StyledDateInput,
  StyledDateRange,
  StyledInputAdornment,
} from './styles';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const DatePicker = ({
  date,
  setDate,
  disabledDates,
  minDate,
  isDropOff,
}: {
  date: DateType;
  setDate: any;
  disabledDates?: any;
  minDate?: Date;
  isDropOff?: boolean;
}) => {
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const datePickerRef = useRef<any>(null);
  const [isRange, setIsRange] = useState<boolean>(false);

  const [state, setState] = useState<any>([
    {
      startDate: isDropOff ? new Date() : minDate || new Date(),
      endDate: isDropOff ? new Date() : minDate || new Date(),
      key: 'selection',
    },
  ]);

  const handleDateChange = (item: any) => {
    const { startDate, endDate } = item.selection;

    setDate({ startDate, endDate });
    setState([item.selection]);

    if (startDate !== endDate) setOpenCalendar(!openCalendar);
  };

  const handleSingleDateChange = (date: Date) => {
    setDate({ startDate: date, endDate: date });
    setState([{ startDate: date, endDate: date, key: 'selection' }]);

    if (date) setOpenCalendar(!openCalendar);
  };

  const handleRangeChange = () => {
    setIsRange(prevIsRange => !prevIsRange);
    setDate({ startDate: '', endDate: '' });
    setState([
      {
        startDate: minDate || new Date(),
        endDate: minDate || new Date(),
        key: 'selection',
      },
    ]);
  };

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setOpenCalendar(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <StyledContainer ref={datePickerRef}>
      <div
        onClick={() => {
          setOpenCalendar(!openCalendar);
        }}
      >
        <StyledDateInput
          label='Event Date'
          placeholder='DD/MM/YYYY'
          value={formatDates(date?.startDate, date?.endDate)}
          size='small'
          autoComplete='off'
          required
          InputLabelProps={{
            shrink: !!date?.startDate,
          }}
          InputProps={{
            startAdornment: (
              <StyledInputAdornment position='end'>
                <div
                  onClick={() => {
                    setOpenCalendar(!openCalendar);
                  }}
                >
                  <CalendarIcon />
                </div>
              </StyledInputAdornment>
            ),
          }}
        />
      </div>
      {openCalendar && (
        <StyledDateRange>
          {isRange ? (
            <DateRange
              editableDateInputs={false}
              onChange={handleDateChange}
              moveRangeOnFirstSelection={false}
              ranges={state}
              // minDate={minDate}
              rangeColors={[theme.primaryColor.royalBlue]}
              showMonthAndYearPickers={false}
              showDateDisplay={true}
              disabledDates={disabledDates}
            />
          ) : (
            <Calendar
              locale={enUS}
              date={state[0].startDate}
              onChange={date => handleSingleDateChange(date)}
              // minDate={minDate}
              color={theme.primaryColor.royalBlue}
              disabledDates={disabledDates}
              showMonthAndYearPickers={false}
            />
          )}
        </StyledDateRange>
      )}
      {!isDropOff && (
        <StyledCheckBoxContainer>
          <StyledCheckboxInput type='checkbox' checked={isRange} onChange={handleRangeChange} />
          <StyledCheckboxLabel>Event spans over multiple days?</StyledCheckboxLabel>
        </StyledCheckBoxContainer>
      )}
    </StyledContainer>
  );
};

export default DatePicker;
