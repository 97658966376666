import { styled } from 'styled-components';

import { StyledInputFieldContainer } from '../FormComponent/styles';

export const FlexBox = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledPhotoLabel = styled.div`
  display: flex;
  align-items: center;
  color: var(--Primary, ${({ theme }) => theme.primaryColor.navyBlue});
  font-family: ${({ theme }) => theme.fontFamily.secondFont};
  font-size: ${({ theme }) => theme.fontSize.get('xs')};
  font-weight: 600;
  line-height: 24px;
  flex: 1 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledDetailsContainer = styled(FlexBox)`
  border-top: 1px solid var(--Primary-variant, ${({ theme }) => theme.primaryColor.softBlue});
  padding: 8px 16px;
`;

export const StyledPhotoContainer = styled(FlexBox)`
  height: inherit;
  align-items: center;
  overflow-y: hidden;
  background-color: ${({ theme }) => theme.primaryColor.softBlue};
  flex: 1 0 0;
`;
export const StyledMainContainer = styled(StyledInputFieldContainer)`
  padding: 0px;
`;
export const StyledPhoto = styled.img`
  height: fit-content;
  max-width: 100%;
  object-fit: contain;
`;

export const StyledPhotoInputContainer = styled(FlexBox)<{ $isPdfFile?: boolean }>`
  flex-direction: column;
  max-width: 363px;
  height: ${({ $isPdfFile }) => ($isPdfFile ? '120px' : '248px')};
  border-radius: 8px;
  border: 1px solid var(--Primary-variant, ${({ theme }) => theme.primaryColor.softBlue});
  background: var(--Gray-00, ${({ theme }) => theme.primaryColor.pristineSnowfall});
  align-self: stretch;
  overflow: hidden;
  cursor: pointer;
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.primaryColor.navyBlue};
`;
