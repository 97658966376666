import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useField } from 'formik';

import { createTheme, ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { theme } from '@src/styles/theme';

import { StyledDatePickerContainer, StyledError } from './styles';
import { InputLayoutProps } from './types';

const newTheme = (prevTheme: any) =>
  createTheme({
    ...prevTheme,
    components: {
      MuiStack: {
        styleOverrides: {
          root: {
            overflow: 'initial !important',
            paddingTop: '0px !important',
            borderColor: theme.primaryColor.lunarElegance,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            padding: '8px !important',
            height: 'inherit',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: theme.primaryColor.lunarElegance,
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            width: '100%',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            marginTop: '-6px',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          shrink: {
            marginTop: '0px',
          },
        },
      },
    },
  });

const Date: React.FC<InputLayoutProps> = ({
  label,
  id,
  minDate,
  disabled = false,
  useFormik = true,
  onDateChange,
  date,
  setDate,
}: InputLayoutProps) => {
  const [field, meta, helpers] = useFormik ? useField(id) : [null, null, null];
  const [selectedDate, setSelectedDate] = useState<any | null>();

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);

    if (useFormik && helpers) {
      helpers.setValue(date);
    }

    if (onDateChange) {
      onDateChange(date);
    }
  };

  useEffect(() => {
    setSelectedDate(date);
  }, [date]);

  useEffect(() => {
    if (useFormik && field && field.value) {
      setSelectedDate(dayjs(field.value));
      setDate && setDate({ startDate: selectedDate, endDate: selectedDate });
    }
  }, [useFormik, field?.value]);

  return (
    <StyledDatePickerContainer>
      <ThemeProvider theme={newTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            <DatePicker
              label={label}
              value={selectedDate}
              onChange={handleDateChange}
              minDate={minDate && (dayjs(minDate) as any)}
              disabled={disabled}
              format='DD/MM/YYYY'
            />
          </DemoContainer>
        </LocalizationProvider>
      </ThemeProvider>
      {meta?.touched && meta?.error && <StyledError>{meta?.error}</StyledError>}
    </StyledDatePickerContainer>
  );
};

export default Date;
