import { theme } from '@src/styles/theme';

const BoxIcon = ({ isSelected = false, isHovered = false }: { isSelected: boolean; isHovered: boolean }) => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.90527 6.81982L10.9994 11.504L19.0386 6.8473'
        stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.999 19.809V11.4949'
        stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.10208 2.27325L4.20708 4.99578C3.09791 5.60995 2.19043 7.14993 2.19043 8.41493V13.5941C2.19043 14.8591 3.09791 16.3991 4.20708 17.0133L9.10208 19.7358C10.1471 20.3133 11.8612 20.3133 12.9062 19.7358L17.8013 17.0133C18.9104 16.3991 19.8179 14.8591 19.8179 13.5941V8.41493C19.8179 7.14993 18.9104 5.60995 17.8013 4.99578L12.9062 2.27325C11.8521 1.68659 10.1471 1.68659 9.10208 2.27325Z'
        stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.5829 12.1366V8.78167L6.88379 3.7583'
        stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default BoxIcon;
