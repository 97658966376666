import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { menuItems, routeMapping } from '@src/lib/constants';
import { logo } from '@src/lib/imgUrl';
import { LoginStateProps } from '@src/redux/type';

import { Header, StyledImage, StyledLabel, StyledLogo, StyledMenuItem, StyledMenuList, Wrapper } from './style';
import { MenuItem } from './types';

const Sidebar = () => {
  const location = useLocation();

  const { role: userRole } = useSelector(
    (state: { loginReducer: { loginState: LoginStateProps } }) => state?.loginReducer?.loginState
  );

  const [hoveredItems, setHoveredItems] = useState<string[]>([]);

  const filterMenuItems = (role: string, items: MenuItem[]) => {
    switch (role) {
      case 'superadmin':
        return items;
      case 'admin':
        return items.filter(item => item.label !== 'Staffs');
      case 'sales':
        return items.filter(item => item.label !== 'Staffs');
      case 'staff':
        return items.filter(item => ['Orders', 'Event Feedback', 'Dish Feedback', 'Reports'].includes(item.label));
      default:
        return [];
    }
  };

  const isSelected = (baseRoute: string): boolean => {
    const normalizePath = (path: string) => path.replace(/\/+$/, '');
    const currentPath = normalizePath(location.pathname);
    baseRoute = normalizePath(baseRoute);
    const matchExactOrSubRoute = currentPath === baseRoute || currentPath.startsWith(`${baseRoute}/`);
    const relatedRoutes = routeMapping[baseRoute] || [];

    const isRelatedRoute = relatedRoutes.some(relatedRoute => currentPath.startsWith(normalizePath(relatedRoute)));

    return matchExactOrSubRoute || isRelatedRoute;
  };

  const roleSpecificMenuItems = filterMenuItems(userRole, menuItems);

  const handleMouseEnter = (route: string) => {
    setHoveredItems(prevHoveredItems => [...prevHoveredItems, route]);
  };

  const handleMouseLeave = (route: string) => {
    setHoveredItems(prevHoveredItems => prevHoveredItems.filter(item => item !== route));
  };

  return (
    <Wrapper>
      <Header>
        <StyledLogo>
          <StyledImage src={logo} alt='logo' />
        </StyledLogo>
      </Header>
      <StyledMenuList>
        {roleSpecificMenuItems.map(({ route, label, IconComponent }, index) => (
          <StyledMenuItem
            $isSelected={isSelected(route)}
            $label={label}
            key={index}
            to={route}
            onMouseEnter={() => handleMouseEnter(route)}
            onMouseLeave={() => handleMouseLeave(route)}
          >
            <div>
              {IconComponent && (
                <IconComponent isSelected={isSelected(route)} isHovered={hoveredItems.includes(route)} />
              )}
            </div>
            <StyledLabel>{label}</StyledLabel>
          </StyledMenuItem>
        ))}
      </StyledMenuList>
      {/* <Footer>
        <p>Footer content goes here...</p>
      </Footer> */}
    </Wrapper>
  );
};

export default Sidebar;
