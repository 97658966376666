import { GeneratePresignedUrlProps, PresignedUrlApiResponseProps } from '@src/components/PhotoInputComponent/types';
import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';

export const generatePresignedUrl = (data: GeneratePresignedUrlProps): Promise<PresignedUrlApiResponseProps> => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.PRESIGNED_URL_GENERATE}`,
    data,
  });
};

export const uploadImage = (url: string, file: File) => {
  return apiRequest({
    method: 'PUT',
    url: url,
    contentType: file.type,
    data: file,
  });
};
