import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';

import { createBox } from '@src/api/box';
import { useHandleCreateEditSubmit } from '@src/hooks/useHandleCreateEditSubmit';
import { Box, CreateBoxRequestProps } from '@src/model/Box';
import { theme } from '@src/styles/theme';

import FormControls from '../FormComponent/formControls';
import { StyledButton, StyledButtonWrapper, StyledControlsContainer, StyledForm } from '../FormComponent/styles';
import { FormValues } from '../FormComponent/types';
import Helmet from '../Helmet';
import GenericModal from '../Modals';

import { schema } from './formSchema';

const CreateEditLayout = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalName, setModalName] = useState('');

  const [defaultValues, setDefaultValues] = useState({
    name: '',
    category: '' as string | Record<string, string>,
    price: '',
    dishes: [],
    quantity: '',
    active: false,
    picture: {},
  });

  const { handleCreateEditSubmit } = useHandleCreateEditSubmit();

  const handleSaveEntity = useCallback(async (name: string, modal: string) => {
    setIsModalOpen(false);
    const loaderRequest = `unique_${Date.now()}`;

    try {
      //   dispatch(startLoaderAction(loaderRequest));
      // if (modal === 'Create Allergen') {
      //   const allergenDetails = {
      //     name,
      //   } as Allergen;
      //   // const result = await createAllergen(allergenDetails);
      //   // fetchAllergenData();
      //   if (result?.status === statusCodes.API_CREATE_SUCCESS_CODE || result?.status === statusCodes.API_SUCCESS_CODE) {
      //     enqueueSnackbar('Success', {
      //       variant: 'success',
      //     });
      //   }
      // } else {
      //   const ingredientDetails = {
      //     name,
      //   } as Ingredient;
      //   // const result = await createIngredient(ingredientDetails);
      //   // fetchIngredientData();
      //   if (result?.status === statusCodes.API_CREATE_SUCCESS_CODE || result?.status === statusCodes.API_SUCCESS_CODE) {
      //     enqueueSnackbar('Success', {
      //       variant: 'success',
      //     });
      //   }
      // }
    } catch (error) {
      console.log(error);
    } finally {
      //   dispatch(stopLoaderAction(loaderRequest));
    }
  }, []);

  const onHandleSubmit = async (values: FormValues) => {
    const boxDetails = {
      name: values.name,
      description: values?.description,
      picture: values?.picture,
      price: values?.price,
      quantity: values?.quantity,
      sapId: values?.sapId,
      active: values?.active,
      dishes: (values?.dishes as string)?.split(',').map(dish => dish.trim()),
    } as CreateBoxRequestProps;

    handleCreateEditSubmit({
      details: boxDetails,
      defaultValues,
      createFunc: createBox,
      updateFunc: async (data, id) => {
        //todo: update Box
      },
    });
  };

  return (
    <>
      <Helmet subHeader={id ? defaultValues.name : 'Create New Box'} />
      <Formik<FormValues>
        initialValues={defaultValues}
        // validationSchema={boxValidationSchema}
        onSubmit={onHandleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setValues, setFieldValue }) => (
          <StyledForm onSubmit={handleSubmit}>
            <StyledControlsContainer>
              <FormControls
                schema={schema({
                  id,
                  setFieldValue,
                })}
                values={values}
                errors={errors}
                touched={touched}
                setValues={setValues}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </StyledControlsContainer>
            <GenericModal
              isOpen={isModalOpen}
              modalName={modalName}
              onRequestClose={() => setIsModalOpen(false)}
              onSave={handleSaveEntity}
            />
            <StyledButtonWrapper>
              <StyledButton
                border={true}
                type='button'
                $bgColor={theme.primaryColor.white}
                color={theme.primaryColor.black}
                onClick={() => navigate(-1)}
              >
                Cancel
              </StyledButton>
              <StyledButton type='submit'>Save</StyledButton>
            </StyledButtonWrapper>
          </StyledForm>
        )}
      </Formik>
    </>
  );
};

export default CreateEditLayout;
