import styled from 'styled-components';

const StyledTableDescription = styled.td`
  text-align: center;
  padding: 12px 0;
  @media (max-width: 600px) {
    font-size: ${({ theme }) => theme.fontSize.get('xs')};
  }
`;

const StyledTableHeaderText = styled.th`
  text-align: center;
  padding-bottom: 20px;
  @media (max-width: 600px) {
    font-size: ${({ theme }) => theme.fontSize.get('xs')};
  }
`;

export const StyledTable = styled.table`
  background-color: ${({ theme }) => theme.primaryColor.white};
  border: 1px solid ${({ theme }) => theme.primaryColor.softBlue};
  width: 93%;
  border-radius: 10px;
  padding: 20px 0px;
  margin: 24px;
  @media (max-width: 1000px) {
    margin: 24px 5px;
  }
  @media (max-width: 400px) {
    padding: 20px 5px;
  }
`;
export const StyledHeaderName = styled(StyledTableHeaderText)``;
export const StyledHeaderSequence = styled(StyledTableHeaderText)``;
export const StyledHeaderAction = styled.th`
  padding-bottom: 10px;
  @media (max-width: 600px) {
    font-size: ${({ theme }) => theme.fontSize.get('xs')};
  }
`;

export const StyledTableDatacontainer = styled.div`
  margin-bottom: 20px;
`;

export const StyledDataName = styled(StyledTableDescription)``;
export const StyledDataSequence = styled(StyledTableDescription)``;
export const StyledDeleteContainer = styled(StyledTableDescription)`
  vertical-align: middle;
  padding: 12px 0;
`;

export const StyledArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledActionButtons = styled.div`
  display: flex;
  width: 60px;
  margin: 0 auto;
  gap: 10px;
`;

export const StyledDeleteButton = styled.div`
  cursor: pointer;
`;

export const StyledEditButton = styled.div`
  cursor: pointer;
`;

export const StyledButtonUp = styled.button`
  text-align: center;
  border: none;
  background-color: ${({ theme }) => theme.primaryColor.white};
`;

export const StyledButtonDown = styled.button`
  text-align: center;
  border: none;
  background-color: ${({ theme }) => theme.primaryColor.white};
`;

export const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.primaryColor.white};
  border-radius: 16px;
  padding: 24px 40px;
  padding-bottom: 24px;
  border: 1px solid ${({ theme }) => theme.primaryColor.softBlue};
  margin: 10px;
`;

export const StyledHeading = styled.div`
  font-size: ${({ theme }) => theme.fontSize.get('m')};
  font-weight: 600;
  color: ${({ theme }) => theme.primaryColor.duskHarbor};
  padding-bottom: 24px;
`;

export const StyledList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledListHeader = styled.div`
  display: flex;
`;

export const StyledListHeaderName = styled.div`
  color: ${({ theme }) => theme.primaryColor.royalGray};
  font-size: ${({ theme }) => theme.fontSize.get('xs')};
  font-weight: 600;
  padding-bottom: 10px;
  flex: 1;
`;

export const StyledListHeaderSequence = styled.div`
  color: ${({ theme }) => theme.primaryColor.royalGray};
  font-size: ${({ theme }) => theme.fontSize.get('xs')};
  font-weight: 600;
  flex: 1;
`;

export const StyledListData = styled.div`
  display: flex;
  padding-bottom: 10px;
`;

export const StyledListDataName = styled.div`
  font-size: ${({ theme }) => theme.fontSize.get('xs')};
  font-weight: 600;
  flex: 1;
  color: ${({ theme }) => theme.primaryColor.duskHarbor};
`;

export const StyledListDataSequence = styled.div`
  font-size: ${({ theme }) => theme.fontSize.get('xs')};
  font-weight: 600;
  color: ${({ theme }) => theme.primaryColor.duskHarbor};
  flex: 1;
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  gap: 50px;
`;

export const StyledInputContainer = styled.div`
  display: flow;
`;

export const StyledErrorMessage = styled.div`
  margin-top: 4px;
  font-weight: 400;
  color: ${({ theme }) => theme.primaryColor.red};
`;

export const StyledInputWrapper = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 570px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export const StyledPartialContainer = styled.div`
  background-color: ${({ theme }) => theme.primaryColor.whteSmoke};
  margin: 0 ${({ theme }) => theme.fontSize.get('l')};
  @media (max-width: 600px) {
    padding: 0.5%;
    margin: 0;
  }
  border: 1px solid ${({ theme }) => theme.primaryColor.softBlue};
  border-radius: 16px;
`;

export const StyledHeaderConatiner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
`;

export const StyledText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.get('m')};
  font-weight: 600;
`;

export const StyledButtonContainer = styled.div`
margin-right:12px;
.css-nfpz5v-MuiButtonBase-root-MuiButton-root {
  background-color: ${({ theme }) => theme.primaryColor.shadePurple};
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.get('s')};
  text-transform: none;
    &:hover {
      background-color: ${({ theme }) => theme.primaryColor.deepCerulean};
    },

}
`;

export const StyledFieldContainer = styled.div`
  display: flex;
  column-gap: 10%;
`;

export const StyledImage = styled.img`
  width: 36px;
  height: 36px;
  cursor: pointer;
`;

export const StyledFormControls = styled.div`
  background-color: ${({ theme }) => theme.primaryColor.whiteSmoke};
  @media (max-width: 600px) {
    padding: 0.5%;
  }
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-self: end;
  padding-right: 12px;
  margin-bottom: 24px;
  gap: 16px;
`;

export const StyledFormSection = styled.form`
  background-color: ${({ theme }) => theme.primaryColor.white};
`;

export const StyledPartialCapacity = styled.div`
  background-color: ${({ theme }) => theme.primaryColor.whiteSmoke};
  padding: 0% 8% 3%;
`;
