export type JsonType = {
  [key: string]: any;
};

function arraysEqual(arr1: any[], arr2: any[]): boolean {
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    const value1 = typeof arr1[i] === 'string' ? { value: arr1[i] } : arr1[i];
    const value2 = typeof arr2[i] === 'string' ? { value: arr2[i] } : arr2[i];
    const { isUpdated } = getUpdatedFields(value1, value2);

    if (isUpdated) return false;
  }

  return true;
}

interface GetUpdatedFieldsProps {
  updatedFields: { setData: JsonType; unsetData: JsonType };
  isUpdated: boolean;
}

const isObject = (value: any) => typeof value === 'object' && value !== null;

// eslint-disable-next-line complexity
export function getUpdatedFields(
  oldObj: JsonType,
  newObj: JsonType,
  ignoreKeys?: Record<string, string>
): GetUpdatedFieldsProps {
  const diff: JsonType = {};
  const unsetDiff: JsonType = {};

  for (const key in newObj) {
    if (ignoreKeys?.[key]) {
      diff[key] = newObj[key];
      continue;
    }

    // for photo value
    if (['picture', 'icon', 'document'].includes(key)) {
      if (oldObj[key]?.url !== newObj[key].url) diff[key] = newObj[key];

      continue;
    }

    // for multi select values or photos values
    // new value is an array
    if (Array.isArray(newObj[key])) {
      // is a photos value
      if (key === 'pictures') {
        for (const keyValue in newObj[key]) {
          if (oldObj[`picture${Number(keyValue) + 1}`]?.url !== newObj[key][keyValue]?.url) {
            diff[key] = newObj[key];
            break;
          }
        }

        continue;
      }

      // old value is also an array and values are not equal
      if (!oldObj[key] || (Array.isArray(oldObj[key]) && !arraysEqual(oldObj[key], newObj[key])))
        diff[key] = newObj[key];

      continue;
    }

    // mostly for single select values
    // either of new value or old value is an object
    if (isObject(newObj[key]) || isObject(oldObj[key])) {
      // old value is an object and new value is a string
      if (isObject(oldObj[key]) && typeof newObj[key] === 'string' && oldObj[key].value === newObj[key]) {
        continue;
      }

      // new value is an object and old value is a string
      if (isObject(newObj[key]) && typeof oldObj[key] === 'string' && newObj[key].value === oldObj[key]) continue;

      // new value and old value both are objects
      if (isObject(newObj[key]) && isObject(oldObj[key])) {
        if (oldObj[key]?.value !== newObj[key]?.value) diff[key] = newObj[key];

        continue;
      }

      // reaching this code means one value is object and other is string and value of those dont match

      // if new value is undefined or '' means value has been unset
      if ([undefined, ''].includes(newObj[key])) {
        // if old value is undefined or '' means value is not changed
        if ([undefined, ''].includes(oldObj[key]?.value)) continue;

        unsetDiff[key] = 1;
        continue;
      }

      // else value is been set
      diff[key] = newObj[key];

      continue;
    }

    // if new value is not equal to old value
    if (oldObj[key] !== newObj[key]) {
      // if new value is undefined or ''
      if ([undefined, ''].includes(newObj[key])) {
        // if old value is undefined or '' means value is not changed
        if ([undefined, ''].includes(oldObj[key])) continue;

        // this means new value is undefined or '' but old value is not , so some value has been unset
        unsetDiff[key] = 1;
        continue;
      }

      // if new value is not undefined or ''
      diff[key] = newObj[key];
    }
  }

  return {
    updatedFields: { setData: diff, unsetData: unsetDiff },
    isUpdated: !!(Object.keys(diff).length || Object.keys(unsetDiff).length),
  };
}

export const getCreateFields = (details: JsonType) => {
  const result: JsonType = {};

  for (const key in details) {
    if (![undefined, ''].includes(details[key])) result[key] = details[key];
  }

  return result;
};
