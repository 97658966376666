import { FieldsProps, FormSchema } from '../FormComponent/types';

interface SchemaProps {
  id: string | undefined;
  setFieldValue: (field: string, value: null | string) => void;
}

export const schema = ({ id }: SchemaProps): FormSchema => {
  return {
    sections: [
      {
        key: 'boxDetails',
        heading: 'Box Details',
        gridColumn: 'repeat(2,1fr)',
        columnGap: '10%',
        inputFields: [
          {
            id: 'name',
            label: 'Name',
            gridColumn: '1 / span 2',
          },

          {
            id: 'sapId',
            label: 'SapId',
            required: false,
          },
          {
            id: 'price',
            label: 'Price',
            type: 'number',
          },
          {
            id: 'description',
            label: 'Description',
            rows: 3,
            gridRow: '3/span 2',
          },
          ...(!id
            ? [
                {
                  id: 'active',
                  label: 'Status',
                  type: 'toggle',
                  isToggleOnLabel: 'Publish',
                  isToggleOffLabel: 'Un-Publish',
                  confirmationPopupData: {
                    label: 'Are you sure you want to change status?',
                  },
                } as FieldsProps,
              ]
            : []),
        ],
      },
      {
        key: 'boxDetails',
        heading: '',
        gridColumn: 'repeat(2,1fr)',
        columnGap: '10%',
        inputFields: [
          {
            id: 'picture',
            label: `Picture`,
            type: 'photo',
            modelName: 'boxes',
            altName: 'Box Picture',
          },
          {
            id: 'dishes',
            label: `Dishes (Eg: <dishId>:<itemCount>,<dishId>:<itemCount>)`,
            type: 'text',
            modelName: 'dishes',
            altName: 'Dishes',
            isMulti: true,
          },
        ],
      },
    ],
  };
};
