import styled from 'styled-components';

export const StyledHelmetContainer = styled.div`
  background-color: ${({ theme }) => theme.primaryColor.pristineSnowfall};
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.primaryColor.silkenMoonlight};
  flex-wrap: wrap;
  gap: 10px;
`;

export const StyledLeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const StyledRightContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const StyledIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const StyledMenuButton = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const StyledMenuItem = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.firstFont};
  font-size: ${({ theme }) => theme.fontSize.get('s')};
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
`;

export const StyledFilterMessage = styled.div`
  color: ${({ theme }) => theme.fontFamily.brightCyan};
  font-family: ${({ theme }) => theme.fontFamily.firstFont};
  font-size: ${({ theme }) => theme.fontSize.get('s')};
  font-style: italic;
  font-weight: 500;
`;

export const StyledText = styled.div`
  display: flex;
  color: ${({ theme }) => theme.primaryColor.duskHarbor};
  font-family: ${({ theme }) => theme.fontFamily.firstFont};
  font-size: ${({ theme }) => theme.fontSize.get('s')};
  font-weight: 500;
  line-height: 20px;
`;

export const StyledItemContainer = styled.div`
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: 1px solid ${({ theme }) => theme.primaryColor.lunarElegance};
  border-radius: 12px;
  cursor: pointer;
`;

export const StyledSearchBar = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const StyledSearchInput = styled.input`
  display: flex;
  padding: 12px 30px 12px 15px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.primaryColor.lunarElegance};
  border-radius: 12px;
  cursor: pointer;
  transition: border-color 0.3s;
  width: 22vw;
  &:focus {
    border-color: ${({ theme }) => theme.primaryColor.royalGray};
    outline: none;
  }

  font-size: 16px;
`;

export const StyledClearButton = styled.button`
  position: absolute;
  right: 8px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 16px;
  color: #888;
`;
